// Inicio.js
import React from "react";
import "../styles/Inicio.css";

const Inicio = () => {
  return (
    <div className="inicio">
      <div className="inicio-content">
        <h1>BIM</h1>
        <h1>Servicios integrales para proyectos de construcción</h1>
        <p>
          En BIM, somos tu aliado para el éxito en proyectos de construcción.
          Ofrecemos una amplia gama de servicios integrales diseñados para
          satisfacer las necesidades de constructores y particulares en todas
          las etapas de sus proyectos.
        </p>

        <h2>Servicios para Constructores:</h2>
        <ul>
          <li>
            <strong>Presupuestos Precisos:</strong> ¿Eres una constructora en
            busca de presupuestos precisos para tus proyectos? En BIM, te
            proporcionamos estimaciones detalladas y transparentes en todas las
            fases de construcción, desde el inicio hasta la finalización.
          </li>
          <li>
            <strong>Diseño y Modelado BIM:</strong> Nuestro equipo experto puede
            ayudarte a modelar tus planos en Revit utilizando la metodología
            BIM, lo que mejora la eficiencia y la calidad de tus diseños.
          </li>
          <li>
            <strong>Apoyo en Licitaciones:</strong> Si necesitas presentar una
            licitación, nuestro personal capacitado puede encargarse de la
            documentación y los procesos necesarios mientras tu equipo se
            concentra en otras actividades críticas.
          </li>
          <li>
            <strong>Supervisión Técnica de Construcción:</strong> Ofrecemos
            servicios de supervisión técnica para garantizar la calidad y el
            cumplimiento de los estándares en tu proyecto de construcción.
          </li>
        </ul>

        <h2>Servicios para Particulares:</h2>
        <ul>
          
            <p>
              ¿Eres un particular que busca construir su hogar? En BIM, también
              te respaldamos. Cuando adquieres un terreno y contratas un
              constructor para hacer realidad tu sueño, es esencial contar con
              un presupuesto comparativo detallado. Esto te permite evaluar de
              manera precisa y objetiva las propuestas de diferentes
              constructores y asegurarte de que estás obteniendo un trato justo.
            </p>
            <p>
              Nuestros presupuestos claros y detallados te proporcionan una
              visión completa de los costos involucrados en tu proyecto
              personal, lo que te permite tomar decisiones informadas y
              controlar los costos desde el principio. En BIM, comprendemos la
              importancia de la transparencia y la planificación sólida en la
              construcción de tu hogar, y estamos aquí para brindarte el apoyo
              necesario en cada paso del camino.
            </p>
          
        </ul>

        <h2>Nuestra Misión</h2>
        <p>
          En BIM, nuestra misión es mejorar la eficiencia y la productividad en
          cada proyecto, reducir los costos y mitigar los riesgos. Nos
          enorgullece brindar soluciones personalizadas tanto para empresas de
          construcción como para personas que sueñan con su propio hogar.
        </p>

        <p>
          ¿Deseas saber cómo podemos ayudarte a alcanzar tus objetivos de
          construcción o necesitas más información sobre nuestros servicios?
          ¡Contáctanos hoy mismo y descubre cómo BIM puede ser tu socio
          estratégico en cada paso de tu proyecto de construcción!
        </p>
      </div>
    </div>
  );
};

export default Inicio;
