import React, { useState, useRef } from "react";
import emailjs from "emailjs-com";

import "../styles/Contacto.css";

const Contacto = () => {
  const [nombre, setNombre] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [telefono, setTelefono] = useState("");
  const [email, setEmail] = useState("");
  const [asunto, setAsunto] = useState("");
  

  const handleSubmit = (e) => {
    e.preventDefault();
  
    emailjs
      .send("service_2xipxon", "contact_form", {
        nombre: nombre,
        empresa: empresa,
        telefono: telefono,
        email: email,
        asunto: asunto,
      },"PGL2vZX_qiuSSvJNe")
      .then(
        (result) => {
          console.log("Email sent successfully:", result.text);
          // Clear the form after successful submission
          setNombre("");
          setEmpresa("");
          setTelefono("");
          setEmail("");
          setAsunto("");
        },
        (error) => {
          console.error("Email sending failed:", error.text);
        }
      );
  };

    // Send the form data using emailJS
  //   emailjs
  //     .sendForm("service_2xipxon", "contact_form", e.target, "PGL2vZX_qiuSSvJNe")
  //     .then(
  //       (result) => {
  //         console.log("Email sent successfully:", result.text);
  //         // Clear the form after successful submission
  //         setNombre("");
  //         setEmpresa("");
  //         setTelefono("");
  //         setEmail("");
  //         setAsunto("");
  //       },
  //       (error) => {
  //         console.error("Email sending failed:", error.text);
  //       }
  //     );
  

  return (
    <div className="contacto">
      <h1>CONTACTENOS</h1>
      <div className="contacto-form">
        <form onSubmit={handleSubmit}>
          <div className="campo">
            <label>Nombre:</label>
            <input
              type="text"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              required
            />
          </div>
          <div className="campo">
            <label>Empresa:</label>
            <input
              type="text"
              value={empresa}
              onChange={(e) => setEmpresa(e.target.value)}
            />
          </div>
          <div className="campo">
            <label>Teléfono:</label>
            <input
              type="tel"
              value={telefono}
              onChange={(e) => setTelefono(e.target.value)}
            />
          </div>
          <div className="campo">
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="campo">
            <label>Asunto:</label>
            <textarea
              value={asunto}
              onChange={(e) => setAsunto(e.target.value)}
              required
            />
          </div>
          
          <button type="submit">Enviar</button>
        </form>
      </div>
    </div>
  );
};

export default Contacto;
