import React from "react";
import "../styles/Servicios.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyCheckDollar,
  faChartLine,
  faDiagramProject,
  faComputer,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import foto1 from "../images/ppto1.png";
import foto2 from "../images/planeacion2.png";
import foto3 from "../images/flujo1.png";
import foto4 from "../images/planeacion1.png";
import foto5 from "../images/obra1.png";

const Servicios = () => {
  return (
    <div className="servicios">
      <h1>SERVICIOS</h1>
      <div className="picture-container">
        <div className="picture">
          <img src={foto4} alt="Service 1" />
          <h3>
            <FontAwesomeIcon icon={faComputer} className="custom-icon" />{" "}
            Implementación Metodología BIM
          </h3>
          <p>
            Implemente la metodología BIM en sus proyectos de construcción para
            mejorar la eficiencia, la productividad y la calidad. Nuestros
            expertos le ayudarán a seleccionar la solución BIM adecuada para sus
            necesidades.
          </p>
        </div>
        <div className="picture">
          <img src={foto5} alt="Service 1" />
          <h3>
            <FontAwesomeIcon icon={faBuilding} className="custom-icon" />{" "}
            Supervisión Técnica
          </h3>
          <p>
            Garantice la calidad y el cumplimiento de las normas en sus
            proyectos de construcción. Nuestros supervisores técnicos le
            ayudarán a supervisar la ejecución de sus proyectos.
          </p>
        </div>
        <div className="picture">
          <img src={foto1} alt="Service 1" />
          <h3>
            <FontAwesomeIcon
              icon={faMoneyCheckDollar}
              className="custom-icon"
            />{" "}
            Presupuestos de obra
          </h3>
          <p>
            Obtenga presupuestos de obra precisos y completos para sus proyectos
            de construcción. Nuestros presupuestos se basan en cálculos
            detallados y en la experiencia de nuestro equipo de profesionales.
          </p>
        </div>
        <div className="picture">
          <img src={foto2} alt="Service 1" />
          <h3>
            <FontAwesomeIcon icon={faDiagramProject} className="custom-icon" />{" "}
            Programación de obra
          </h3>
          <p>
            Planifique sus proyectos de construcción de forma eficiente y
            eficaz. Nuestros expertos le ayudarán a desarrollar cronogramas
            detallados que se ajusten a sus necesidades y presupuesto.
          </p>
        </div>
        <div className="picture">
          <img src={foto3} alt="Service 1" />
          <h3>
            <FontAwesomeIcon icon={faChartLine} className="custom-icon" /> Flujo
            de caja
          </h3>
          <p>
            Controle los costos y el flujo de caja de sus proyectos de
            construcción. Nuestros flujos de caja le ayudarán a tomar decisiones
            informadas sobre sus proyectos.
          </p>
        </div>

        
      </div>
    </div>
  );
};

export default Servicios;
