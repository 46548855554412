// Nosotros.js
import React from "react";
import "../styles/Nosotros.css";

const Nosotros = () => {
  return (
    <div className="nosotros">
      <div className="nosotros-content">
        <h1>Un equipo de profesionales con más de 15 años de experiencia</h1>
        <p>
          Somos un grupo de profesionales altamente capacitados y apasionados
          por la construcción. En BIM, nos enorgullecemos de nuestra dedicación
          a la excelencia en cada proyecto que abordamos. Nuestra empresa se
          especializa en la elaboración de presupuestos de obra detallados, la
          programación minuciosa de proyectos, el seguimiento del flujo de caja
          y el control eficiente de costos.
        </p>
        <p>
          Cada miembro de nuestro equipo aporta conocimientos especializados y
          una profunda comprensión de los desafíos y oportunidades que enfrenta
          la industria. Trabajamos en estrecha colaboración con nuestros
          clientes para garantizar la ejecución exitosa de sus proyectos,
          aprovechando nuestra experiencia y conocimientos para superar
          obstáculos y alcanzar metas.
        </p>
        <p>
          Nuestra misión es ofrecer soluciones integrales y resultados
          destacados en cada proyecto que emprendemos. Nos comprometemos a
          ayudar a nuestros clientes a tomar decisiones informadas, mejorar la
          eficiencia y la productividad en sus proyectos de construcción,
          reducir costos y minimizar riesgos. En BIM construimos relaciones
          sólidas basadas en la confianza y el éxito compartido.
        </p>
      </div>
    </div>
  );
};

export default Nosotros;
