// Footer.js
import React from "react";
import { Link } from "react-router-dom";
import "../styles/Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

//import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  const whatsappURL = "https://wa.me/573001665640";
  return (
    <footer className="footer">
      <div className="footer-middle">
        <div className="footer-links">
          <ul>
            <li>
              <Link className="no-underline" to="/">
                <a href="#">Inicio</a>
              </Link>
            </li>
            <li>
              <Link className="no-underline" to="/nosotros">
                <a href="#">Nosotros</a>
              </Link>
            </li>
            <li>
              <Link className="no-underline" to="/servicios">
                <a href="#">Servicios</a>
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer-contact">
          <h4>Contactenos</h4>
          <p>
            <FontAwesomeIcon
              icon={faEnvelope}
              style={{
                color: "darkkhaki",
                fontSize: "18px",
              }}
            />{" "}
            &nbsp;&nbsp;contacto@bim.com.co
          </p>
          <p>
            <a
              href={whatsappURL}
              style={{
                color: "white", // Cambia el color a blanco
                fontSize: "16px",
                textDecoration: "none", // Quita el subrayado
              }}
            >
              <FontAwesomeIcon
                icon={faWhatsapp}
                style={{
                  color: "darkkhaki",
                  fontSize: "18px",
                }}
              />
              &nbsp;&nbsp;(300) 166-5640
            </a>
          </p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>
          &copy; {new Date().getFullYear()} BIM. Todos los derechos
          reservados.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
